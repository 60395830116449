import { styled } from "goober";
import { Card } from "clutch/src/Card/Card";

export const PageContainer = styled("div")`
  width: 100%;

  div.logo {
    display: flex;
    align-items: center;
    gap: var(--sp-1);
    padding: var(--sp-4);

    svg.logo {
      width: var(--sp-6_5);
      height: auto;
    }

    svg.wordmark {
      height: var(--sp-3);
      width: auto;
    }
  }
`;

export const SurveyCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: var(--sp-8);

  width: 80vw;
  max-width: 600px;
  margin: var(--sp-12) auto;

  .disable {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);

  label {
    display: flex;
    gap: var(--sp-3);
    align-items: center;
    cursor: pointer;

    input {
      appearance: none;
      font: inherit;
      color: var(--shade3);
      width: var(--sp-4);
      height: var(--sp-4);
      border: 0.15em solid var(--shade3);
      margin: 0;
      display: grid;
      place-content: center;
      &:checked {
        color: var(--blue);
        border-color: var(--blue);
      }
      &:checked::before {
        transform: scale(1);
      }
      &::before {
        content: "";
        width: var(--sp-2);
        height: var(--sp-2);
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--blue);
      }
    }

    &:hover {
      input {
        border: 0.15em solid var(--shade1);
      }
      input:checked {
        color: #59c4ff;
        border-color: #59c4ff;
      }
      input::before {
        box-shadow: inset 1em 1em #59c4ff;
      }
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: var(--sp-1);
  }

  button {
    align-self: flex-start;
  }
`;
