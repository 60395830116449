import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button";
import { TextArea } from "clutch/src/TextArea/TextArea.jsx";

import eventBus from "@/app/app-event-bus.mjs";
import { EVENT_UNINSTALL_SURVEY } from "@/app/constants.mjs";
import BlitzLogo from "@/inline-assets/blitz-logo.svg";
import BlitzWordmark from "@/inline-assets/wordmark.svg";
import {
  Form,
  PageContainer,
  SurveyCard,
} from "@/marketing//UninstallSurvey.style.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

function randomizeReasons(t) {
  const reasons = [
    {
      value: "no-longer-needed",
      label: t("common:survey.reason.noLongerNeeded", "No longer needed"),
    },
    {
      value: "fps-impact",
      label: t(
        "common:survey.reason.fpsImpact",
        "Significant impact on FPS while playing",
      ),
    },
    {
      value: "high-resource-usage",
      label: t(
        "common:survey.reason.highResourceUsage",
        "Uses too much resources",
      ),
    },
    {
      value: "bugs-crashes",
      label: t("common:survey.reason.bugsAndCrashes", "Bugs and crashes"),
    },
    {
      value: "better-apps-available",
      label: t(
        "common:survey.reason.betterAppsAvailable",
        "Better apps available",
      ),
    },
    {
      value: "missing-game-support",
      label: t(
        "common:survey.reason.missingGameSupport",
        "Doesn't support games I play",
      ),
    },
    {
      value: "ads",
      label: t("common:survey.reason.intrusiveAds", "Intrusive ads"),
    },
  ];

  return reasons
    .map((r) => {
      return { ...r, order: Math.random() };
    })
    .sort((a, b) => a.order - b.order);
}

export default function UninstallSurvey() {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const [displayOther, setDisplayOther] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const machineId = searchParams.get("id");

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      // get all the checked reasons
      const checkBoxReasons = Array.from(
        event.target.querySelectorAll("input:checked"),
      ).map((input) => input.value);

      // get the other feedback
      const textFeedback = Array.from(
        event.target.querySelectorAll("textarea"),
      ).map(({ name, value }) => ({
        [name]: value,
      }));

      eventBus.emit(EVENT_UNINSTALL_SURVEY, {
        machineId,
        checkBoxReasons,
        textFeedback,
      });
      setIsSubmitted(true);
    },
    [machineId],
  );

  const reasons = React.useMemo(() => randomizeReasons(t), [t]);

  return (
    <PageContainer>
      <div className="logo">
        <BlitzLogo className="logo" />
        <BlitzWordmark className="wordmark" />
      </div>

      <SurveyCard>
        <div>
          <h5 className="type-h5">
            {!isSubmitted
              ? t("common:survey.pending.title", "Uninstall Survey")
              : t("common:survey.submitted.title", "Thank you!")}
          </h5>
          <p className="type-body1 shade1">
            {!isSubmitted
              ? t(
                  "common:survey.pending.subtitle",
                  "Thank you for using our app. We're sorry to see you go. Please take a moment to let us know why you're uninstalling and how we can improve.",
                )
              : t(
                  "common:survey.submitted.subtitle",
                  "We appreciate your feedback, it will help us improve the app.",
                )}
          </p>
        </div>

        {!isSubmitted && (
          <Form onSubmit={onSubmit}>
            {reasons.map((reason) => (
              <label key={reason.value}>
                <input type="checkbox" value={reason.value} />
                <p className="type-body2">{reason.label}</p>
              </label>
            ))}

            <div className="group">
              <label>
                <input
                  className="type-body2"
                  type="checkbox"
                  value="other"
                  onClick={() => setDisplayOther(!displayOther)}
                />
                <p className="type-body2">{t("common:other", "Other")}</p>
              </label>
              {displayOther && (
                <TextArea
                  name="other"
                  placeholder={t(
                    "common:survey.other.placeholder",
                    "Please elaborate...",
                  )}
                  rows="2"
                />
              )}
            </div>

            <div>
              <p className="type-body1">
                {t("common:survey.additional.title", "Additional feedback")}
              </p>
              <TextArea
                name="additional"
                placeholder={t(
                  "common:survey.additional.placeholder",
                  "We'd love to hear your thoughts...",
                )}
                rows="4"
              />
            </div>
            <Button emphasis="high" type="submit">
              {t("common:submit", "Submit")}
            </Button>
          </Form>
        )}
      </SurveyCard>
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["home:meta.uninstallSurvey.title", "Blitz.gg - Uninstall Survey"],
    description: [
      "home:meta.uninstallSurvey.description",
      "We're sorry to see you go. Please take a moment to let us know why you're uninstalling and how we can improve.",
    ],
  };
}
